<template>
  <div class="text-center">
    <b-overlay
      :show="downloading"
      rounded="sm"
    >
      <b-card>
        <b-row
          align-v="center"
        >
          <b-col class="text-center p-0">
            <div class="mb-3 mt-3">
              <feather-icon
                class="mx-2"
                color="#ea5455"
                size="160"
                icon="CheckCircleIcon"
              />
              <h1
                v-if="orderdetails.userdata"
                class="mt-3"
              >
                {{ $t('thank_you') }}, <strong>{{ orderdetails.userdata.name.toUpperCase() }}</strong>
              </h1>
              <h4 class="my-2">
                {{ $t('delivery_confirmed') }}
              </h4>
              <b-row>
                <b-col class="text-center">
                  <b-button
                    variant="outline-primary"
                    class="mx-1"
                    @click="download"
                  >
                    {{ $t('print_cover_note') }}
                  </b-button>
                  <b-button
                    type="button"
                    variant="primary"
                    class="mx-1"
                    :to="{name: 'hypermarket-order'}"
                  >
                    {{ $t('continue_delivery') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>

          </b-col>
          <b-col
            class="text-center"
            align-v="center"
          >
            <div class="m-3">
              <b-container class="border">
                <b-row
                  cols="2"
                  class="border"
                >
                  <b-col class="p-1 border">
                    <h2>{{ id }}</h2>
                    <h5>{{ $t('delivery_id') }}</h5>
                  </b-col>
                  <b-col class="p-1 border">
                    <h2>{{ orderdetails.details ? totalRM : 'RM ' + 0 }}</h2>
                    <h5>{{ $t('amount') }}</h5>
                  </b-col>
                  <b-col class="p-1 border">
                    <h2>{{ date }}</h2>
                    <h5>{{ $t('delivery_confirmation') }}</h5>
                  </b-col>
                  <b-col class="p-1 border">
                    <h2>{{ deliveryDate }}</h2>
                    <h5>{{ $t('delivery_date') }}</h5>
                  </b-col>
                </b-row>
              </b-container>
              <b-container class="mt-2 border" />
              <b-container class="mt-2 border">
                <b-row
                  cols="1"
                  class="border"
                >
                  <b-col class="p-1 border">
                    <h2>{{ $t('delivery_address') }}</h2>
                    <hr>
                    <h6
                      v-for="order in orderdetails.details"
                      :key="order.id"
                    >
                      {{ order.dropoffaddress }}
                      <hr>
                    </h6>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import { BOverlay } from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BOverlay,
  },
  data() {
    return {
      total: 0,
      downloading: false,
      id: localStorage.getItem('deliveryId'),
      orderdetails: [],
      date: new Date().toISOString().slice(0, 10),
      deliveryDate: null,
    }
  },
  computed: {
    totalRM() {
      const total = this.orderdetails.details.map(index => this.total + +index.smarttruck_cut + +index.pickup_charge)
      let convert
      if (typeof total === 'object') {
        convert = total.reduce((init, i) => init + i, 0)
      } else {
        convert = total
      }
      return new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(convert)
    },
  },
  watch: {
    orderdetails(val) {
      this.orderdetails = val
    },
  },
  created() {
    this.getDeliveryDetails()
  },
  methods: {
    async download() {
      const self = this
      self.downloading = true
      const response = await axios.get(`/deliveries/${this.id}/pdf`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `convernote-${this.id}.pdf`)
      document.body.appendChild(fileLink)

      fileLink.click()
      self.downloading = false
    },
    async getDeliveryDetails() {
      const self = this
      const headers = {
        Accept: 'application/json',
      }
      const response = await axios.get(`${this.$appURL}getDeliveryDetails/${self.id}`, { headers })
      self.orderdetails = response.data.order
      self.deliveryDate = self.orderdetails.details[0].delivery_date
    },
  },
}

</script>
